<template>
  <v-container id="about" tag="section">
    <base-main-title class="mt-9" :title="'من نحن'"/>
    <p class="text-center">
     <strong> مركز القمر للإعلام الرقمي :</strong>
       
   
      مركز اعلامي معرفي غير ربحي مختص بنشر الفكر في مجالات عدة
      وبناء رصيد معرفي هام عبر التثقيف الرقمي (موشن كرافيك ) وما يتماشى
      مع الحركة الثقافية والعلمية الحديثة مستهدفا فئات مجتمعيّة مختلفة
      وبأسلوب يتوافق مع ما يحصل اليوم من تطوّرات هائلة.
      <br />
      <strong> سبب اختيار التثقيف الرقمي :</strong>
     
      بما أن الثقافة الرقمية باتت اليوم ركنا أساسيا للحصول على المعرفة في
      مختلف المجالات، والإنتقال من أسلوب الورقة والقلم الى الأسلوب
      العصري الحديث ، انبثقت فكرة الحاجة في هذا الوقت الى هذا النوع من
      العمل لانه يعد من الاليات المهمة في تثقيف المجتمع
      إيمانا منا بأن المعرفة لا يجب أن تبقى حبيسة الأدراج ورفوف المكتبات بل
      يجب أن تصل إلى كل شخص يبحث عنها، خاصة مع التطورات التقنية
      والتكنولوجية التي فرضت اللجوء إلى مثل هذا النوع من النشر.
      <br />
      <strong>الاهداف او الرسالة :</strong>
       
      بما أنَّ الركيزة الاساسية في الحفاظ على المجتمع هي الوعي، فالمجالات
      التثقيفية التي يهتم بها هذا المركز تصب بهذا الجانب، فيُعْنى المركز برد
      الاشكالات الفكرية والعقائدية ، وتسليط الضوء على المشاكل الاجتماعية
      الشخصية والمجتمعية وتشخيص العلاج لها، كما يسعى لتقديم المحتوى
      الطبي والقانوني الموثوق وذلك من خلال مقالات طبية وقانونية متنوعة
      وغنية.
      <br />
   <strong>منهجية العمل :</strong>
        
      الأعتماد على ركيزة اساسية وهي الشريعة الاسلامة المتمثلة بالنبي (ص)
      ورسالته القرآن الكريم واهل بيته الكرام (عليهم السلام ) والامتداد لهذا
      الفكر متمثلاً بالمرجعية الدينية العليا.
      <br />
     <strong>كيف يدار المركز :</strong>
        
      يُدار العمل داخل المركز بخبرات مهنية في العالم الرقمي بمختلف
      المواقع والمنصّات بأسلوب يتميّز بالحداثة .
      <br />
     <strong> اقسام المركز :</strong>
       
      ويحتوي مركزُنا على وحدات اساسية وحدة الاعداد ووحدة الانتاج
      ووحدة التعليق الصوتي ووحدة النشر والتوزيع ووحدة الاشراف والتدقيق .
      <br />
    <strong>استيراد الخبرات :</strong>
      
      لابد من الإشارة الى أن المركز يرتكز في أخذ الفائدة من أصحاب الخبرة
      بما يملكون من كفاءة عالية نوظفها خدمة للعمل من اساتذة في الحوزة
      العلمية وباحثين لتحقيق عامل الإبداع والتطوّر.
    </p>  
  </v-container>
</template>

<script>
export default {
  name: 'HomeAbout',
}
</script>
